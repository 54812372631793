import {
    HD4kIcon,
    IndianIcon,
    MoviesIcon,
    OnlyfansIcon,
    PRTIcon,
} from "./assets/icons";

const API_ENDPOINT =
    window.location.hostname === "localhost"
        ? "http://localhost:8788/api"
        : "/api";

const config = {
    SITENAME: window.env.SITENAME || "DoodStream",
    api: {
        list: `${API_ENDPOINT}/list`,
        search: `${API_ENDPOINT}/search`,
        info: `${API_ENDPOINT}/info`,
        tag: `${API_ENDPOINT}/tag`,
        taglist: `${API_ENDPOINT}/taglist`,
        channel: `${API_ENDPOINT}/channel`,
    },
    DOODSTREAM_URL: process.env.DOODSTREAM_URL || "https://doods.pro",
    channels: [
        {
            name: "Indian",
            icon: IndianIcon,
            color: "orange.400",
            to: "/channel/Indian",
            desc: "Indian adult moveis and web series, full length top quality videos 18+ only.",
        },
        {
            name: "PRT",
            icon: PRTIcon,
            color: "#3b88c3",
            to: "/channel/PRT",
            desc: "Well organized full length videos available in 720p and 1080p quality for streaming and downloading.",
        },
        {
            name: "HD 4K",
            icon: HD4kIcon,
            color: "orange.400",
            to: "/channel/HD-4K",
            desc: "Full length 4K, 2K and VR adult videos available for streaming and downloading.",
        },
        {
            name: "Movies",
            icon: MoviesIcon,
            color: "purple.500",
            to: "/channel/Movies",
            desc: "Premium adult (18+) movies from different producers available for streaming and downloading.",
        },
        {
            name: "OnlyFans",
            icon: OnlyfansIcon,
            color: "#00aff0",
            to: "/channel/OnlyFans",
            desc: "Paid OnlyFans videos for free available for streaming and downloading.",
        },
    ],
};

export default config;
