import { Box, Heading, Icon, Text, VStack } from "@chakra-ui/react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import Error from "../components/Error";
import Loading from "../components/Loading";
import Pagination from "../components/Pagination";
import VideoList from "../components/VideoList";
import config from "../config";
import fetcher from "../lib/fetcher";
import useSWR from "swr";

function Channel() {
    const { name } = useParams();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    searchParams.set("name", name.toLowerCase());
    const { data, error } = useSWR(
        config.api.channel + "?" + searchParams.toString(),
        fetcher
    );

    document.title = name + " - " + config.SITENAME;

    if (error) {
        if (error.code === 404) {
            navigate("/tag/" + name);
        } else {
            return <Error status={error.code} message={error.message} />;
        }
    }
    if (!data) return <Loading />;

    const setPage = (page) => {
        searchParams.set("page", page);
        setSearchParams(searchParams);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const channel = config.channels.find(
        (channel) =>
            channel.name.toLowerCase() === name.toLowerCase().replace("-", " ")
    );

    return (
        <VStack gap={0}>
            <Box
                bgColor={"black.800"}
                w={"full"}
                textAlign={"center"}
                py={[6, 8]}
            >
                <Heading
                    size={["2xl", "3xl"]}
                    textTransform={"capitalize"}
                    mb={1}
                >
                    <Icon
                        as={channel.icon}
                        color={channel.color}
                        mb={-2}
                        me={4}
                    ></Icon>
                    {channel.name}
                </Heading>
                <Text fontSize={["small", "md"]} mx={8}>
                    {channel.desc}
                </Text>
            </Box>

            <VideoList files={data.files} />
            <Pagination
                currentPage={
                    searchParams.has("page")
                        ? parseInt(searchParams.get("page"))
                        : 1
                }
                totalPage={data.total_pages}
                onPageChange={setPage}
            />
        </VStack>
    );
}

export default Channel;
